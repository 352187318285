const getBreakpoint = (breakpoint, direction = 'width') => `@media only screen and (min-${direction}: ${breakpoint}px)`;

export const BREAKPOINTS = {
    medium: 640,
    tablet: 768,
    large: 1024,
    xlarge: 1440,
    wide: 1920,
};

export const MEDIA_QUERIES = {
    medium: getBreakpoint(BREAKPOINTS.medium),
    tablet: getBreakpoint(BREAKPOINTS.tablet),
    large: getBreakpoint(BREAKPOINTS.large),
    xlarge: getBreakpoint(BREAKPOINTS.xlarge),
    wide: getBreakpoint(BREAKPOINTS.wide),
    mediumHeight: getBreakpoint(BREAKPOINTS.medium, 'height'),
    tabletHeight: getBreakpoint(BREAKPOINTS.tablet, 'height'),
    largeHeight: getBreakpoint(BREAKPOINTS.large, 'height'),
};

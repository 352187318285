import React from 'react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES } from '../../utils/breakpoints';
import backgroundLeft from '../../images/background-left.png';
import backgroundRight from '../../images/background-right.png';

const LeftImage = styled.img`
    position: fixed;
    height: 200px;
    top: 144px;
    left: 5%;
    z-index: -1;

    ${MEDIA_QUERIES.medium} {
        height: 300px;
    }

    ${MEDIA_QUERIES.tablet} {
        height: 400px;
    }

    ${MEDIA_QUERIES.xlarge} {
        height: 500px;
        left: 10%;
    }

    ${MEDIA_QUERIES.wide} {
        height: 600px;
        left: 15%;
    }
`;

const RightImage = styled.img`
    position: fixed;
    height: 200px;
    top: 40%;
    right: 5%;
    z-index: -1;

    ${MEDIA_QUERIES.medium} {
        height: 300px;
    }

    ${MEDIA_QUERIES.tablet} {
        height: 400px;
    }

    ${MEDIA_QUERIES.xlarge} {
        height: 500px;
        right: 10%;
    }

    ${MEDIA_QUERIES.wide} {
        height: 600px;
        right: 15%;
    }

    ${MEDIA_QUERIES.tabletHeight} {
        bottom: 20%;
        top: inherit;
    }
`;

const Background = () => (
    <>
        <LeftImage src={backgroundLeft} alt="" />
        <RightImage src={backgroundRight} alt="" />
    </>
);

export default Background;

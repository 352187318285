import React from 'react';
import styled from '@emotion/styled';
import Button from '../components/Button';
import Background from '../components/layout/Background';

const Main = styled.main`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 32px;
`;

const H1 = styled.h1`
    font-size: 128px;
    margin: 0;
`;

const NotFoundPage = () => (
    <Main>
        <H1>404</H1>
        <h2>This is not the page you are looking for</h2>
        <Button to="/">Accueil</Button>
        <Background />
    </Main>
);

export default NotFoundPage;
